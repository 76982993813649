import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "F:/web/active/infoamazonia-mercurio/src/components/reports/report-layout.js";
import Image from "!url-loader!../../images/fotos/GP1SU06V_High_res.jpg";
export const meta = {
  name: "brazil",
  title: "New gold rush moves illicit mercury markets in Roraima",
  intro: "South America’s largest and most industrialized economy, Brazil, doesn’t produce mercury. But its booming illegal gold mines can't work without the toxic substance.",
  by: "Text by Sam Cowie. Videos by Rafael Norton, Luiz Felioe Saleh e Erik Von Poser.",
  image: Image,
  next: {
    slug: "guyana",
    title: "The Toxic Metal in the Shadow of the Gold Industry",
    description: "Despite its health and environmental risk, mercury is widely used in gold mining, and to miners in the Amazon, the silvery liquid is almost as valuable as the gold itself."
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Sidenote = makeShortcode("Sidenote");
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`Brazil's most northern state`}</strong>{` Roraima doesn’t have a `}<a parentName="p" {...{
        "href": "https://www.bbc.com/portuguese/internacional-48534473"
      }}>{`single legalized`}</a>{` artisanal gold mining pit. But the seven metre high `}<a parentName="p" {...{
        "href": "https://g1.globo.com/rr/roraima/noticia/2020/06/08/monumento-ao-garimpeiro-e-alvo-de-pichacao-em-boa-vista.ghtml"
      }}>{`Monument to Miners statue`}</a>{` in the centre of its quiet capital, Boa Vista, leaves little doubt of where many local loyalties lie.`}</p>
    <p>{`Each day,fleets of cars, trucks,`}<a parentName="p" {...{
        "href": "https://g1.globo.com/rr/roraima/noticia/2020/01/09/exercito-prende-15-garimpeiros-em-barcos-carregados-de-material-para-garimpo-na-terra-yanomami.ghtml"
      }}>{`boats`}</a>{`, `}<a parentName="p" {...{
        "href": "https://g1.globo.com/rr/roraima/noticia/2020/07/25/aviao-e-apreendido-empista-clandestina-em-regiao-de-garimpo-ilegal-no-interior-de-roraima.ghtml"
      }}>{`small planes`}</a>{` and even `}<a parentName="p" {...{
        "href": "https://folhabv.com.br/noticia/POLICIA/Ocorrencias/Helicoptero-que-era-usado-no-garimpo-e-apreendido-no-Paredao/65710"
      }}>{`helicopters`}</a>{` ferry men with mining supplies and gold to and from hundreds of illegal mining sites on indigenous reserves and rivers in this sparsely populated Amazon state. Some of the vehicles carry containers of mercury, the toxic silver substance widely used by miners to process gold.`}</p>
    <Video url="https://www.youtube.com/embed/7iIYvI604ac" mdxType="Video" />
    <p>{`On a recent early morning, at the end of June, military police stopped a car of men heading to Boa Vista, returning from an illegal mining pit in the district of Alto Alegre, where part of Brazil’s vast Yanomami indigenous reserve is located. Two Yanomami had been reportedly shot to death and others wounded by miners in the region days before. Upon searching the car, officers found eight bullets, a set of digital scales and two small Coca-Cola bottles containing 10kg of mercury; a relatively large roadside seizure of the substance but just a fraction of what is used in the state every year.`}</p>
    <p>{`South America’s largest and most industrialized economy, Brazil, doesn’t produce mercury. And even before 2017,`}<a parentName="p" {...{
        "href": "http://www.mercuryconvention.org/DNNAdmin/AllENGLISHNewsEntry/tabid/3444/articleType/ArticleView/articleId/158175/language/en-US/Brazil-brings-to-74-the-number-of-future-Parties-to-the-Minamata-Convention.aspx"
      }}>{`when the country ratified the Minamata Convention`}</a>{`, an international treaty to protect human health from mercury emissions, sales were officially banned and federal agencies kept `}<a parentName="p" {...{
        "href": "https://www.icmbio.gov.br/cepsul/images/stories/legislacao/Instrucao_normativa/2015/in_ibama_08_2015_estabelece_cadastro_tecnico_federal_atividades_poluidoras__rvg_p_ibama_395_1989_p_435_1989_p_32_1995_p_46_1996.pdf"
      }}>{`strict tabs`}</a>{` on registered imports.`}</p>
    <blockquote>
      <p parentName="blockquote">{`According to studies, artisanal gold miners in the amazon use mercury and the new gold rush has certainly led to an increase of illicit mercury use.`}</p>
    </blockquote>
    <p>{`But in recent years, there’s been a notable uptick of illegal mining in Roraima, especially on the `}<a parentName="p" {...{
        "href": "https://news.mongabay.com/2019/07/yanomami-amazon-reserve-invaded-by-20000-miners-bolsonarofails-to-act/"
      }}>{`Yanomami indigenous reserve`}</a>{`. And given that almost all Brazilian Amazon artisanal gold miners use mercury, according to government studies, this new gold rush has certainly led to an increase of illicit mercury use.`}</p>
    <Video url="https://www.youtube.com/embed/s6wbj5-VR8w" mdxType="Video" />
    <p>{`Mercury used in thousands of garimpos across Brazil’s Amazon is typically smuggled in from neighbouring countries or imported legally to use in the country’s industrial production and then funnelled to clandestine networks.`}</p>
    <p>{`Brazil’s Federal Prosecutors Office’s 2020 report “Illegal gold mining in the Amazon: legal frameworks and controversial issues” highlights the importance of “command and control actions on borders and informal mercury trading markets” if Brazil is to succeed in its Minamata Convention targets.`}</p>
    <p>{`“We need more permanent and structured inspections on the Brazilian side,” said Roraima congresswoman Joênia Wapixana, Brazil’s only indigenous lawmaker. “But also we need stricter measures from the mercury producing countries,” she added.`}</p>
    <h2>{`Garimpo in Roraima`}</h2>
    <p>{`Today, it’s thought that thousands of garimpeiros are digging for gold in Roraima’s mineral-rich indigenous reserves, destroying natural habitats and endangering local populations, increasingly with the COVID-19 crisis. Repeated operations by Brazil’s Federal Police, environmental enforcement agencies and armed forces to tackle illegal mining in recent years have led to multiple arrests, large seizures of gold and other assets, but ultimately have failed to stem the tide.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Most of the state's economy continues to run from illegal mining and so the pressure is huge" said one source.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGNqqIlNAT/xAAbEAACAwADAAAAAAAAAAAAAAABAgADERIhI//aAAgBAQABBQLjjGsNDUJV6QdOmMP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAQUAAAAAAAAAAAAAAAAAAREQISIxUf/aAAgBAQAGPwLKmy7I4S0f/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFR8P/aAAgBAQABPyEqVqaxyoFpqwuGzkB8DB7xP//aAAwDAQACAAMAAAAQ5N//xAAYEQACAwAAAAAAAAAAAAAAAAAAARExUf/aAAgBAwEBPxBUSsP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAASH/2gAIAQIBAT8QrX//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFh0f/aAAgBAQABPxBtD9MZwEIb78jFRciRK3GGrq4jrg5GqV3KSbsG9DP/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/a18989589fb695699de7e5d3d936fcd1/e93cc/GP1SU06I_High_res.webp 300w", "/static/a18989589fb695699de7e5d3d936fcd1/b0544/GP1SU06I_High_res.webp 600w", "/static/a18989589fb695699de7e5d3d936fcd1/68fc1/GP1SU06I_High_res.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/a18989589fb695699de7e5d3d936fcd1/73b64/GP1SU06I_High_res.jpg 300w", "/static/a18989589fb695699de7e5d3d936fcd1/3ad8d/GP1SU06I_High_res.jpg 600w", "/static/a18989589fb695699de7e5d3d936fcd1/6c738/GP1SU06I_High_res.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/a18989589fb695699de7e5d3d936fcd1/6c738/GP1SU06I_High_res.jpg",
            "alt": "Miners are digging for gold in Roraima’s mineral-rich indigenous reserves",
            "title": "Miners are digging for gold in Roraima’s mineral-rich indigenous reserves",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Miners are digging for gold in Roraima’s mineral-rich indigenous reserves `}<strong parentName="em">{`Photo by Greenpeace`}</strong>{` `}</em></p>
    <p>{`The majority of the miners themselves are poor, often exploited men - sometimes women - at the bottom of a lucrative pyramid of contraband gold, illegal commerce and logistics that moves billions of dollars annually across Brazil.`}</p>
    <p>{`Exact figures are impossible to come by, due to the illicit nature of the trade. But it’s estimated that thousands of kilos of gold - including, more recently, increasing amounts from neighbouring Venezuela - are illegally extracted and trafficked from Roraima every year, robbing the state of much needed tax revenue.`}</p>
    <p>{`The process is enabled by the state’s poverty, its porous borders and overwhelmed enforcement agencies, but also, by corrupted local officials, unscrupulous businessmen and investors. “There is an enormous social tolerance in Roraima society, the wildcat miner isn’t seen as a criminal,” said Alisson Marugal, a Federal Prosecutor in Roraima.`}</p>
    <p>{`It’s a risk that compensates, for some at least. Global gold prices are at record highs, around US$62,000 a kilo (as on September 4), while Brazil’s environmental and indigenous protection agencies have been `}<a parentName="p" {...{
        "href": "https://www.reuters.com/article/us-health-coronavirus-brazil-environment/exclusive-brazil-scales-back-environmental-enforcement-amid-coronavirus-idUSKBN21E15H"
      }}>{`hobbled by the COVID-19 crisis`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.reuters.com/article/us-brazil-environment/televised-raid-on-illegal-amazonmining-angers-brazil-government-leads-to-sacking-idUSKCN21W31A"
      }}>{`interference`}</a>{` from the government of President Jair Bolsonaro.`}</p>
    <p>{`The far-right leader was elected president in 2018 and `}<a parentName="p" {...{
        "href": "https://g1.globo.com/rr/roraima/eleicoes/2018/noticia/2018/10/29/jair-bolsonaro-venceem-12-das-15-cidades-de-roraima.ghtml"
      }}>{`won a thumping victory`}</a>{` here in Roraima after a campaign replete with pro-garimpeiro rhetoric, including promises to loosen environmental regulations and legalize mining on indigenous reserves.`}</p>
    <Video url="https://www.youtube.com/embed/Vqef9JdC1D0" mdxType="Video" />
    <blockquote>
      <p parentName="blockquote">{`"If I was the king of Roraima, 20 years from now it would be as rich as japan," Bolsonaro said during a `}<a parentName="p" {...{
          "href": "https://folhabv.com.br/noticia/POLITICA/Roraima/Bolsonaro---Se-eu-fosse-rei-de-Roraima--estado-teria-economia-igual-a-do-Japao-/38719"
        }}>{`visit in 2018`}</a>{`.`}</p>
    </blockquote>
    <p>{`For decades, droves of wildcat miners from across Brazil have flocked to this Amazonian El Dorado, hoping to find their fortunes. During the 1980s, some forty thousand garimpeiros invaded the Yanomami land, which is bigger than Portugal.`}</p>
    <p>{`As a result, some 15 – 20 percent of the tribe died out from diseases and violence, anthropologists say. The miners were eventually expelled in 1992 when the reserve was officially demarcated by the Brazilian government, though they always returned.`}</p>
    <p>{`In July this year, Federal Police arrested Pedro Emiliano Garcia, 59, an infamous `}<a parentName="p" {...{
        "href": "https://folhabv.com.br/noticia/CIDADES/Capital/Condenadopor-genocidio-de-indios-recebeu-auxilio-emergencial/67085"
      }}>{`former gold miner`}</a>{` -the only living Brazilian to be convicted with genocide, following a 1993 massacre on the Yanomami land - with 2kg of gold in Boa Vista, `}<a parentName="p" {...{
        "href": "http://www.pf.gov.br/imprensa/noticias/2020/07-noticias-de-julho-de-2020/pf-prende-suspeito-de-atuar-na-logistica-do-garimpo-em-terra-indigena-ianomami"
      }}>{`accused of operating`}</a>{` planes ferrying miners to the reserve and being the “owner” of a pit there.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "746px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABA//EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAVnYIe9kn//EABgQAQEBAQEAAAAAAAAAAAAAAAIBAwQy/9oACAEBAAEFAtbZLuyn0wKeerRh4ZjQf//EABYRAQEBAAAAAAAAAAAAAAAAAAARQf/aAAgBAwEBPwHEf//EABYRAQEBAAAAAAAAAAAAAAAAAAASQf/aAAgBAgEBPwHVP//EABoQAAIDAQEAAAAAAAAAAAAAAAARAQISITH/2gAIAQEABj8C56UrlsypIK5sjV4cs//EABoQAQEBAAMBAAAAAAAAAAAAAAEAEUFRYXH/2gAIAQEAAT8h1L+L1eZimTqWt7AjHOJ2V1rf/9oADAMBAAIAAwAAABDj3//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EGH/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxAYf//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQVFxkbH/2gAIAQEAAT8QIK6DTBBoXRUb4qYHRqxPsRal5V4lpKGlhzL2Zyovqf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/62786a741109ea817671bd630b79ca09/e93cc/foto-policia-federal.webp 300w", "/static/62786a741109ea817671bd630b79ca09/b0544/foto-policia-federal.webp 600w", "/static/62786a741109ea817671bd630b79ca09/16dd7/foto-policia-federal.webp 746w"],
            "sizes": "(max-width: 746px) 100vw, 746px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/62786a741109ea817671bd630b79ca09/73b64/foto-policia-federal.jpg 300w", "/static/62786a741109ea817671bd630b79ca09/3ad8d/foto-policia-federal.jpg 600w", "/static/62786a741109ea817671bd630b79ca09/2ca71/foto-policia-federal.jpg 746w"],
            "sizes": "(max-width: 746px) 100vw, 746px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/62786a741109ea817671bd630b79ca09/2ca71/foto-policia-federal.jpg",
            "alt": "The Federal Police in Brazil recently arrested a man charged with the killing of indigenous community members. The arrestee was caught with gold and cash money.",
            "title": "The Federal Police in Brazil recently arrested a man charged with the killing of indigenous community members. The arrestee was caught with gold and cash money.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`The Federal Police in Brazil recently arrested a man charged with the killing of indigenous community members. The arrestee was caught with gold and cash money.`}</em></p>
    <p>{`Isolated from the rest of the country, the only other Brazilian state capital accessible by road from Roraima is Manaus, the jungle metropolis capital of Amazonas state, itself only accessible by plane, a long river boat trip or by navigating the 870km unpaved BR-319 highway, which can be virtually impossible to cross during the November to March rainy season.`}</p>
    <p>{`But Roraima shares porous land borders with Venezuela and Guyana. The former is reeling from its own gold rush and an unprecedented humanitarian crisis with thousands of desperate Venezuelan refugees settling in the neighbouring Brazilian state in recent years. There has also been an uptick in the flow of contraband, including gold, weapons, cocaine and mercury, local security experts say, with exploited Venezuleans often coerced to work for criminal groups.`}</p>
    <p>{`Meanwhile Guyana, one of South America’s poorest countries, with a population of 780,000, that recently began drilling huge offshore oil reserves, continues to import huge amounts of mercury – at least `}<a parentName="p" {...{
        "href": "https://www.stabroeknews.com/2019/08/31/news/guyana/mercury-imports-capped-at-1000-flasks-per-year/"
      }}>{`34.5 tonnes`}</a>{` in 2019- to use in its own artisanal gold mining operations. Some of it is trafficked into Roraima, according to multiple local sources heard by InfoAmazonia who named Guyana as the main source of illicit mercury in the state.`}</p>
    <p>{`“The access of this mercury (from Guyana) to Brazilians is very easy,” said one Roraima illegal mining expert.`}</p>
    <h2>{`Easy access`}</h2>
    <p>{`Roraima’s border with Guyana is formed by the Takutu River. At many points it’s less than 100m wide. It has illegal crossings and during the dry season it can even be possible to wade across. The river is a well-established route of people trafficking and contraband between the two countries for items such as alcohol, food products, medical supplies and, sources said, mercury.`}</p>
    <p>{`“They don’t need to come via the road. They come by the river and bring the quantity that they want. It’s very easy to buy,” a source said.`}</p>
    <p>{`“The region, due to its inhospitable characteristics, is a great facilitator for any illicit transport,” a law enforcement source said.`}</p>
    <p>{`According to a source from Brazil’s Federal Highway police, responsible for roadside seizures, some mercury is smuggled across the border with Guyana using taxis and taken to Boa Vista. This version was also corroborated by sources heard for this report in Guyana’s capital Georgetown. The substance’s density also makes it an easy product to smuggle.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"A 2 litre Coca-Cola bottle will hold 20Kg of mercury," said one source. "It's higly unlikely that you'll ever find a tonne of mercury, this is what makes iinspections difficult."`}</p>
    </blockquote>
    <p>{`As well as the density, mercury trafficking is aided by the substance’s somewhat legal ambiguity. Unlike cocaine, marijuana or gold, for example, despite its destructive qualities, many people are unaware that it’s illegal, including many police, some said. “Most people don’t see it as a crime,” said a federal police agent formerly working in Guyana.`}</p>
    <p>{`Several other sources suggested smaller amounts of mercury were brought in from Venezuela, especially via the Uraricoera River, with small amounts being brought over at a time.`}</p>
    <p><strong parentName="p">{`Yanomami indigenous territory and mapped illegal mining sites`}</strong></p>
    <iframe src="https://infoamazonia.org/en/embed/?map_only=1&map_id=59813&width=960&height=480" width="960" height="480" frameBorder="0"></iframe>
    <p>{`“It’s the so-called ant job, each one takes 0.5L or 1L,” a law enforcement source said.`}</p>
    <p>{`Another source, with illegal mining connections, said that Roraima’s garimpo planes and helicopters landing on the Venezuelan side of the border would sometimes pick up part of the mercury and distribute it to the Yanomami territory.`}</p>
    <p>{`There is even some evidence to suggest that mercury smuggled into Roraima is being taken to other regions of Brazil too.`}</p>
    <p>{`Last year, highway police in Roraima’s neighbouring state Amazonas arrested a man with 150kg of mercury in four metal flasks, apparently valued at BR$90,000, along with 35 thousand packets of cigarettes. He said he had left Boa Vista and was transporting mercury to Manaus, which would then be sent to Itaituba, the most important illegal mining hub of the Brazilian Amazon.`}</p>
    <Video url="https://www.youtube.com/embed/6mVE6V9aaWs" mdxType="Video" />
    <p>{`Elsewhere in Brazil, a large amount of mercury is thought to enter via Bolivia, the world’s second-biggest importer after India, importing 196 tonnes in 2018 according to World Bank figures.`}</p>
    <p>{`Rondônia and Mato Grosso states, which both share huge land borders with Bolivia, have their own extensive illegal mining industries as well as road access to much of the Brazilian Amazon.`}</p>
    <p>{`Earlier this year, two men were arrested on the BR-364 highway in Rondônia, transporting `}<a parentName="p" {...{
        "href": "https://g1.globo.com/ro/rondonia/noticia/2020/01/25/80-quilos-de-mercurio-sao-apreendidos-com-dupla-em-carro-na-br-364.ghtml"
      }}>{`80kg of mercury`}</a>{` that they said would be sold at illegal mining pits in the city of Ariquemes.`}</p>
    <p>{`The number of potential routes shows that mercury is an agile substance that can adapt to its given conditions, including clampdowns, seizures and price hikes. Like many illegal substances, it ultimately arrives where there is the demand.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Elsewhere in Brazil, a large amount of mercury is thought to enter via bolivia, the world's second-biggets importer`}</p>
    </blockquote>
    <p>{`“It’s like the same logic as drug trafficking,” said a Federal Police forensics expert based in Brazil’s Tapajós River region, who preferred to remain anonymous. “Larger amounts are taken to locations and then broken down into smaller quantities to be sold on.”`}</p>
    <p>{`Across Brazil’s Amazon, goods sold on far-flung illegal mining sites - many times only reachable by plane or boat - such as water, Coca-Cola, tinned food, alcohol and internet use, are sold at greatly inflated prices and mercury is said to be the same.`}</p>
    <p>{`Prices of mercury in Brazil seem to fluctuate depending on the region. One source said that mercury could be bought locally in Roraima for BR$500 a kilo while Brazil’s 2018 National Mercury Inventory Emissions Study had it priced at BR$600 – 1200 a kilo, depending on the state. Mercury is also seemingly widely available `}<a parentName="p" {...{
        "href": "https://www.construvolts.com.br/mercrio-vivo-azougue-100g-p136"
      }}>{`online`}</a>{`, but for much higher prices, BR$850 for 100g, for example.`}</p>
    <Sidenote side="right" mdxType="Sidenote">
	Brazil’s 2018 National Mercury Inventory Emissions Study had it priced at BR$600 – 1200 a kilo, depending on the state.
    </Sidenote>
    <p>{`Meanwhile, in the 2020 report `}<a parentName="p" {...{
        "href": "http://www.escolhas.org/wpcontent/uploads/2020/05/TD_04_GARIMPO_A-NOVA-CORRIDA-DO-OURO-NA-AMAZONIA_maio_2020.pdf"
      }}>{`The new gold rush in the Amazon`}</a>{` by Brazil environmental NGO Instituto Escolhas, a pro-garimpo source in Brazil’s Tapajós River region alleged that mercury was priced at BR$1500 and therefore was used in “moderation”.`}</p>
    <h2>{`Mecury emissions`}</h2>
    <p>{`Estimates on the amount of mercury released into Brazil’s environment each year vary greatly.`}</p>
    <p>{`Garimpeiro groups insist that emissions have declined in recent years due to increased `}<a parentName="p" {...{
        "href": "https://listamercadolivre.com.br/recuperador-de-merc%C3%BArio-cadinho-para-garimpo"
      }}>{`use of retorts`}</a>{`– a metal object that looks like a large kettle, easily available to buy and relatively cheap online - which can recover large amounts of mercury. Retort retailers claim 100 percent can be recovered while studies suggest more like 70 per cent as opposed to standard filtering methods which recover 50 per cent on average.`}</p>
    <p>{`Brazil’s most comprehensive study of mercury use in artisanal mining, the National Mercury Emissions Inventory, published in 2018, concluded that somewhere between 18.5 and 221.5 tonnes of mercury were released into the environment in 2016, around two thirds of which goes into the atmosphere and the rest into soil and rivers.`}</p>
    <p>{`The huge variation depends on the amount of gold illegally extracted, which according to the report could range from 50 – 100 per cent the declared amount, though due to the difficulties of measuring, could also be higher.`}</p>
    <p>{`In the first four months of 2020 Brazil exported 29 tonnes of gold, a 15 per cent increase from the same period in 2019, according to the Instituto Escolhas report.`}</p>
    <blockquote>
      <p parentName="blockquote">{`In the first four months of 2020 Brazil exported 29 tonnes of gold, a 15 percent increase from the same period in 2019, according to the Instituto Escolhas report.`}</p>
    </blockquote>
    <hr></hr>
    <p>{`According to the National Mercury Emissions Inventory, the amount of mercury released into the environment also depends on how common the use of retorts and filtering techniques are.`}</p>
    <p>{`Filtering techniques, that can recover upto 50 percent of mercury, were practically universal among the 26 artisanal mining groups studied in the report, which makes sense given the substance’s cost, the report noted.`}</p>
    <p>{`The report concluded that on average, artisanal mining groups in Brazil would use 1g of mercury to produce 1g of gold, though the global average is 3:1 mercury to gold.`}</p>
    <p>{`“With a kilo of mercury you can work for months,” one source said. “But with so many people working, little by little the damages accumulate.”`}</p>
    <h2>{`Health impacts`}</h2>
    <p>{`In a historic recent decision, prompted by the COVID-19 crisis at the request of indigenous advocacy groups, Brazil’s Supreme Court ordered that authorities must act to remove the thousands of garimpeiros from the Yanomami reserve where at least four indigenous have died from the disease.`}</p>
    <p>{`But even if all the miners leave, a highly ambitious outcome, reducing the risks of COVID-19 transmission, another silent killer remains: mercury.`}</p>
    <p>{`The substance’s use in artisanal mining can poison local rivers and food chains. In 2019, at a public hearing at Brazil’s House of Representatives, researchers presented a study which linked cerebral palsy in indigenous children in mining areas to prenatal exposure to mercury.`}</p>
    <Video url="https://youtube.com/embed/gFpOCFpCn8A" mdxType="Video" />
    <p>{`The same year, research by Brazil’s Oswaldo Cruz Foundation found that `}<a parentName="p" {...{
        "href": "https://oglobo.globo.com/brasil/estudo-da-fiocruzmostra-que-56-dos-ianomamis-tem-mercurio-acima-do-limite-23852233"
      }}>{`56 per cent of`}</a>{` indigenous women and children tested on the Yanomami indigenous reserve, had mercury levels above World Health Organization limits.`}</p>
    <p>{`Paulo Cesar Basta, a professor of public health, who conducted the research, highlighted mercury’s dangers: its longevity and ability to stay in the environment for a long time, its ability to transit between waters, soils and fishes and its capacity to accumulate and grow in humans and other animals.`}</p>
    <p>{`“It puts a whole generation of children at risk,” he said. Today, Roraima’s indigenous lands face increased pressure from illegal mining and mercury use, partly due to President Jair Bolsonaro’s promises to legalize garimpo activity, local advocates say.`}</p>
    <p>{`Pressures also come from within communities. Pro-mining Roraima Senator and Bolsonaro ally Chico Rodrigues recently recorded a video on the Napoleão indigenous community, encouraging the activity, alongside pro-garimpo indigenous leaders including Irisnaide Silva who `}<a parentName="p" {...{
        "href": "https://abracambrasil.org.br/noticias/217-bolsonaro-recebe-indigenas-no-palacio-do-planalto-e-defende-mineracao-e-agropecuaria-em-suas-terras"
      }}>{`met with Bolsonaro`}</a>{` in the capital Brasília last year.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Mercury puts a whole generation of children at risk," Paulo Cesar Basta, a Professor in Publich Health"`}</p>
    </blockquote>
    <p>{`Meanwhile, on the Raposa Serra do Sol indigenous reserve, the state’s second largest reserve, the recent invasion of some 2000 illegal gold miners and widespread use of mercury risks poisoning water supplies and food chains.`}</p>
    <p>{`“We’re scared that with the beginning of the rainy season, this material (mercury) is going to end up reaching rivers and streams that weren’t contaminated before,” said Ivo Cípio Aureliano, a lawyer and legal advisor to Roraima’s Indigenous Council.`}</p>
    <h2>{`Record seizure`}</h2>
    <Sidenote side="right" mdxType="Sidenote">
	Brazil imported on average 25 tonnes annually and imported 20 tonnes in 2018, according to the World Bank.
    </Sidenote>
    <p>{`Brazil continues to import mercury for its own industrial production, mostly to São Paulo and the country’s wealthy southern state Santa Catarina. Between 2006 and 2016 Brazil imported on average 25 tonnes annually and imported 20 tonnes in 2018, according to the World Bank.`}</p>
    <p>{`In 2018, Brazil’s environmental agency Ibama seized a record 430kg of mercury and intercepted another 1700kg, which were being imported legally by Santa Catarina chemical company Quimidrol from Turkey, which Ibama alleged would be diverted to use in the Amazon’s illegal mining pits.`}</p>
    <p>{`For years, the company had imported mercury that was used in Brazil’s orthodontology market, the use of which became obsolete when new dental technologies emerged, investigators said.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Brazil imported on average 25 tonnes annually and imported 20 tonnes in 2018, according to the Wolrd Bank.`}</p>
    </blockquote>
    <p>{`The mercury seized however, according to Ibama, was due to be delivered to a shell company in Mato Grosso state, on the Amazon’s frontier. It received 8700kg of mercury from the chemical company between 2014 and 2017.`}</p>
    <p>{`Court documents seen by InfoAmazonia of the company’s appeal, which was rejected, mentioned extracts of a classified Ibama report that noted the shell company was connected to a known supplier of illicit mercury in Brazil’s southern Amazon region.`}</p>
    <p>{`“With the seizure of such a large amount it would be reasonable to imagine there would have been a gap in the market and that contraband routes would have increased,” said an investigator that worked on the case.`}</p>
    <h2>{`Bigger picture`}</h2>
    <p>{`Sources heard by InfoAmazonia in Roraima said that the vast majority of people smuggling mercury into the state were small time, even if ultimately working on the behalf of big time illegal mining investors.`}</p>
    <p>{`Machines like dredging platforms that fill the rivers of the Yanomami territory require minimal start-up costs of BR$150,000 and the owners will often also own a legitimate business like a gas station, a hotel, a supermarket or clothes shop.`}</p>
    <p>{`“It will never be identified who is the owner,” said one source. “He’ll put an experienced miner as his manager,” he added. “And this guy will have someone acquire the mercury.”`}</p>
    <p>{`Dredging platforms and other machines used in illegal mining, such as moinhos (rock crushers) on the Raposa Serra do Sol reserve, require vast amounts of gasoline to run constantly and it’s common in Roraima that trucks carrying thousands of litres of gasoline are stopped and their cargoes seized.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Machines like dredging platforms that fill the rivers of the Yanomami territory require minimal start-up costs of BR$150,000`}</p>
    </blockquote>
    <p>{`Mercury and men often reach far-flung mining pits via small aircraft and plane companies operating in Roraima that have received millionaire public contracts have also been `}<a parentName="p" {...{
        "href": "https://oglobo.globo.com/brasilanac-abre-processo-paraapurar-possiveis-irregularidades-de-empresa-de-taxi-aereo-suspeita-de-atuar-em-garimpo-ilegal-1-24530676"
      }}>{`investigated`}</a>{` for operating illegal mining aerial logistics.`}</p>
    <p>{`“Without the logistics you don’t have garimpo,” said Mr. Marugal, the Federal Prosecutor.`}</p>
    <p>{`Plane transport in Roraima is such a lucrative market that it’s thought that it has even led to `}<a parentName="p" {...{
        "href": "http://g1.globo.com/brasil/noticia/2011/05/dono-de-empresa-aerea-e-morto-tiros-em-frente-pizzaria-de-boa-vista.html"
      }}>{`murder`}</a>{` between `}<a parentName="p" {...{
        "href": "https://mp-rr.jusbrasil.com.br/noticias/100044596/acusado-de-executar-empresario-da-aviacao-e-condenado-a-21-anos"
      }}>{`rival businessmen`}</a>{` in the past.`}</p>
    <p>{`Helicopters are also being increasingly used, according to one source and there have been several recent reported seizures, after efforts by enforcement agencies to destroy clandestine landing strips.`}</p>
    <h2>{`Venezuelan Gold`}</h2>
    <p>{`Complicating matters further for Roraima, gold from Venezuela’s hundreds of illegal mines is now being increasingly trafficked into Roraima and out of Brazil into the global supply chain, Federal Police investigations have shown.`}</p>
    <p>{`Last year, according to Brazil’s Comex Stat, at least 668kg of gold, thought by federal agents to be taken from Venezuela and the Yanomami reserve, was officially exported from Roraima, despite the state not having one legalized operation.`}</p>
    <p>{`Gold was the state’s second biggest export in 2019, with India being the main buyer. That year, the state’s biggest import was aeroplanes and associated parts, essential to the logistics of the illegal gold mining trade.`}</p>
    <blockquote>
      <p parentName="blockquote">{`To add to the problems in Roraima a state, increasing quantities of gold from Venezuela's hundreds of illegal mines are now being trafficked into brazilian territory.`}</p>
    </blockquote>
    <p>{`Earlier this month, a Brazilian woman was arrested in the border town of Pacaraima with 11kg of gold and $US4200 cash. The same day, Federal Police `}<a parentName="p" {...{
        "href": "https://g1.globo.com/rr/roraima/noticia/2020/08/06/pf-deflagra-operacao-contra-comercio-e-contrabando-de-ouro-da-terra-yanomami-e-venezuela.ghtml"
      }}>{`raided several addresses`}</a>{` in Roraima, as well as São Paulo, targeting a criminal organization responsible for handling gold illegally taken from Venezuela and the Yanomami territory.`}</p>
    <p>{`One of the addresses is the same at the heart of the scandal that broke `}<a parentName="p" {...{
        "href": "https://g1.globo.com/rr/roraima/noticia/2019/12/06/pfmira-grupo-que-contrabandeou-12-tonelada-de-ouro-davenezuela-por-rr-e-movimentou-r-230-milhoes.ghtml"
      }}>{`late last year`}</a>{` which involved Brazilian and Venezuelan criminal networks, with one Venezuelan wanted by Interpol.`}</p>
    <p>{`The group, according to the federal police, moved at least 1200kg of gold over a period of two years. The gold was smuggled from Venezuela into Roraima in small amounts using mules and then later taken to São Paulo where it was then exported abroad to India and the United Arab Emirates.`}</p>
    <p>{`“We are a frontier state, with all of the fragilities that come along with that,” said Mr. Marugal, the Federal Prosecutor.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      